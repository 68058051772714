const sha256 = require('CommonUtils/sha256');
const { uniqWith } = require('CommonUtils/operations/uniqWith');
const { isEqual } = require('CommonUtils/operations/isEqual');
const commonAnalytics = require('./analytics/common.analytics.js');

const DEFAULT_PROMOTION_PUSH_OFFSET = 20;
const PRODUCT_PUSH_TYPES = Object.freeze({
  MODULE_VUE: 'productModuleVue',
  MODULE_WPC: 'productModuleWpc',
  MODULE_ECI: 'productModuleEci',
  WISHLIST: 'productWishlist',
  MULTIPLE_ADD_TO_CART: 'productMultipleAddToCart',
});

const VENDOR_MODULES = Object.freeze({
  BUY_LOOK: 'Compra el look',
  SIMILAR_PRODUCTS: 'PRODUCTOS_SIMILARES',
  CROSS_SELLING: 'PRODUCTOS RELACIONADOS',
  NOTIFY_ME: 'Avísame',
});

function sendChangeUnitsInCart(productData, ACTION) {
  if (ACTION === 'PLUS') {
    sendAddUnitsInCart(productData);
  } else {
    decreaseUnitsInCart(productData);
  }
}

function sendAddUnitsInCart(productData, crossSellingType, indexPushed, chat_id) {
  if (productData.quantity > 1) {
    sendRemoveFromCartClick(productData, productData.quantity - 1);
  }
  sendAddToCart(productData, crossSellingType, indexPushed, chat_id);
}

function addProductToCartHandler(productDataLayer, crossSellingType, indexPushed, chat_id) {
  return (result) => {
    if (!result?.ok) {
      sendAddToCartIntent(false);
      return;
    }

    // Send event to server
    sendAddUnitsInCart(productDataLayer, crossSellingType, indexPushed, chat_id);
    sendAddToCartIntent(true);
  }
}

function sendAddToCartIntent(success) {
  const event = {
    event: 'custom_event',
    event_data: {
      event_category: 'add to cart debug',
      event_action: `add to cart intent ${success ? 'OK' : 'KO'}`,
      event_label: undefined,
    }
  }

  window.dataLayer.push(event);
}

function decreaseUnitsInCart(productData) {
  sendRemoveFromCartClick(productData, productData.quantity + 1);
  sendAddToCart(productData);
}

function sendAddToCart(productData, crossSellingType, indexPushed, chat_id) {
  var dataLayerValue = {};
  let hierarchy = '';
  let isNewSearchExp = false;

  if (window.dataLayer && window.dataLayer.length > 0) {
    productData.page_type = window.dataLayer[0]?.page?.type;
    setThirdDriver(productData);
    hierarchy = window.dataLayer[0].page?.hierarchy?.join('/') || '';
    isNewSearchExp = window.location.pathname?.includes('search-nwx');
  }
  if (isNewSearchExp) {
    crossSellingType = 'new-search-experience';
  }
  const ai_index_push = window.dataLayer.filter((ev) => ev.event === 'add_to_cart_serps_ai').length + 1;
  const cdp_index_push = window.dataLayer.filter((ev) => ev.event === 'add_to_cart_art').length + 1;
  const pageHierarchy = window.dataLayer[0]?.page?.hierarchy?.join('/') || [];

  switch (crossSellingType) {
    case 'cdp-carousel':
      productData.vendor = 'art::' + pageHierarchy + '::Te recomendamos';
      dataLayerValue = {
        event: 'add_to_cart_art',
        index_pushed: cdp_index_push,
        ['art_product_' + cdp_index_push]: JSON.parse(JSON.stringify(productData)),
      };
      break;
    case 'chatbox':
      productData.vendor = `serps_ai::${hierarchy}::chatbot`;
      productData.parent_id = `chatID>${chat_id}`;

      dataLayerValue = {
        event: 'add_to_cart_serps_ai',
        index_pushed: ai_index_push,
        ['serps_ai_product_' + ai_index_push]: JSON.parse(JSON.stringify(productData)),
      };
      break;
    case 'vue':
      dataLayerValue = {
        event: 'add_to_cart_vue',
        index_pushed: indexPushed || 1,
        ['vue_product_' + indexPushed]: JSON.parse(JSON.stringify(productData)),
      };
      break;
    case 'wpc':
      dataLayerValue = {
        event: 'add_to_cart_wpc',
        index_pushed: indexPushed || 1,
        ['wpc_product_' + indexPushed]: {
          ...productData,
          vendor: productData.vendor?.replace('eci::', 'wpc::'),
        },
      };
      break;
    case 'rich_relevance':
      dataLayerValue = {
        event: 'add_to_cart_rr',
        index_pushed: indexPushed || 1,
        ['rr_product_' + indexPushed]: productData,
      };
      break;
    case 'product_store':
      dataLayerValue = productModuleDatalayer(productData);
      break;
    case 'new-search-experience':
      productData.vendor = 'SERPS/' + pageHierarchy;
      dataLayerValue = {
        event: 'add_to_cart',
        product: productData,
      };
      break;
    default:
      dataLayerValue = {
        event: 'add_to_cart',
        product: productData,
      };
      break;
  }
  window.dataLayer.push(dataLayerValue);
}

function productModuleDatalayer(productData) {
  const dataLayer = window.dataLayer || [];
  const index     = dataLayer.filter(function(d){        
    return d.event && d.event === 'add_to_cart_eci';
  }).length;
  const indexPushed = index + 1;
  const e = {
    event : 'add_to_cart_eci',
    system_event: true,
    index_pushed: indexPushed        
  }          
  e['eci_product_'+indexPushed] = productData;

  return e;
}

function sendMultipleAddToCartClick(productsData, indexPushed) {
  const index = indexPushed ? indexPushed + 1 : 1;
  const dataLayerValue = {
    event: 'add_to_cart_multi',
    index_pushed: index,
    [`products_${index}`]: productsData,
  };
  window.dataLayer.push(dataLayerValue);
  return index;
}

function sendRemoveFromCartClick(productData, quantity) {
  const dataLayerValue = {
    event: 'remove_from_cart',
    product: { ...productData, quantity },
  };
  window.dataLayer.push(dataLayerValue);
}

function sendProductClick(productData, crossellingType) {
  let isNewSearchExp = false;
  var eventName = '';
  var dataName = '';
  let pageHierarchy = '';
  if(productData.product_store_datalayer) {
    productData = productData.product_store_datalayer;
    crossellingType = 'product_module';
  }
  if (window.dataLayer && window.dataLayer.length > 0) {
    pageHierarchy = window.dataLayer[0].page.hierarchy.join('/');
    isNewSearchExp = window.location.pathname?.includes('search-nwx');
  } else {
    pageHierarchy = '';
  }
  if (isNewSearchExp) {
    crossellingType = 'new-search-experience'
  }
  switch (crossellingType) {
    case 'cdp-carousel':
      productData.vendor = 'art::' + pageHierarchy + '::Te recomendamos';
      eventName = 'art_click';
      dataName = 'art_product_click';

      break;
    case 'vue': // CROSS_SELLING_TYPES.VUE:
      eventName = 'vue_click';
      dataName = 'vue_product_click';
      break;
    case 'wpc': // CROSS_SELLING_TYPES.WPC:
      eventName = 'wpc_click';
      dataName = 'wpc_product_click';
      productData.vendor = productData.vendor?.replace('eci::', 'wpc::');
      break;
    case 'product_module':
      eventName = 'eci_click';
      dataName = 'eci_product_click';
      break;
    case 'new-search-experience':
      productData.vendor = 'SERPS/' + pageHierarchy;
      eventName = 'product_click';
      dataName = 'product';
      break;
    default:
      eventName = 'product_click';
      dataName = 'product';
      break;
  }
  const dataLayerValue = {
    system_event: true,
    event: eventName,
    [dataName]: JSON.parse(JSON.stringify(productData)),
  };
  window.dataLayer.push(dataLayerValue);
}

function sendProductPreviewLinkToPDPClick(productData, crossSellingType, isRichRelevance = false) {
  if (isRichRelevance) {
    sendRichRelevanceProductClick(productData);
  } else {
    sendProductClick(productData, crossSellingType);
  }
}

function sendProductModulesPrint(
  productModulesData,
  crossellingType,
  index,
  moduleName = 'PRODUCTOS_SIMILARES',
  addVendor = true,
) {
  if (!Array.isArray(productModulesData) || !productModulesData.length) {
    return;
  }
  const productModules = productModulesData.map((product) => {
    return { ...product };
  });
  const maxItemsPerPush = 4;
  let eventName = '';
  let dataName = '';
  let vendor;

  switch (crossellingType) {
    case 'rr': // CROSS_SELLING_TYPES.RR:
      eventName = 'product_module_push_rr';
      dataName = 'product_modules_rr_';
      vendor = 'rr';
      break;
    case 'vue': // CROSS_SELLING_TYPES.VUE:
      eventName = 'product_module_push_vue';
      dataName = 'product_modules_vue_';
      break;
    case 'wpc': // CROSS_SELLING_TYPES.WPC:
      eventName = 'product_module_push_wpc';
      dataName = 'product_modules_wpc_';
      vendor = 'wpc';
      break;
    default:
      eventName = 'product_module_push_eci';
      dataName = 'product_modules_eci_';
      vendor = 'eci';
      break;
  }

  let productModuleIndex = index ? index + 1 : 1;
  if (moduleName === VENDOR_MODULES.BUY_LOOK) vendor = 'eci'; //vendor = getPageVendor(VENDOR_MODULES.BUY_LOOK);
  const numberOfPushesNeeded = Math.ceil(productModules.length / maxItemsPerPush);
  var numberOfPush = 1;
  while (numberOfPush <= numberOfPushesNeeded) {
    const moduleProductsName = `${dataName}${productModuleIndex}`;
    const dataLayerValue = {
      system_event: true,
      event: eventName,
      index_pushed: productModuleIndex,
      [moduleProductsName]: [],
    };
    for (const [index, productModule] of productModules.entries()) {
      if (index >= (numberOfPush - 1) * maxItemsPerPush && index <= numberOfPush * maxItemsPerPush - 1) {
        if (productModule) {
          productModule.position = index + 1;
          if (addVendor) {
            const productVendor = `${vendor}::${productModule.category?.join('/')}::${moduleName}`;
            if (crossellingType !== 'vue') productModule.vendor = productVendor;
          }
          dataLayerValue[moduleProductsName].push(productModule);
        }
      }
    }
    window.dataLayer?.push(dataLayerValue);
    productModuleIndex += 1;
    numberOfPush += 1;
  }

  return numberOfPush - 1;
}

function sendCartProducts(products) {
  const dataLayerValue = {
    event: 'cart_show',
    products:
      (products &&
        products.length &&
        products.map((product) => {
          return product.data_layer;
        })) ||
      [],
  };
  window.dataLayer.push(dataLayerValue);
}

function sendAddToWishListClick(product, index, wishlistType = 'wishlist') {
  const indexPushed = index ? index + 1 : 1;
  const dataLayerValue = {
    system_event: true,
    event: 'add_to_wishlist',
    wishlist_type: wishlistType,
    index_pushed: indexPushed,
    ['product_' + indexPushed]: product,
  };
  window.dataLayer.push(dataLayerValue);
  return indexPushed;
}

function sendUserPush(userData) {
  const userdataLayerValue = {};
  userdataLayerValue.postal_code = userData.zipCode;
  userdataLayerValue.cookies = userData.cookiesPolicy;
  userdataLayerValue.registered = userData.id ? true : false;
  userdataLayerValue.atg_id = userData.atg_id || undefined;
  userdataLayerValue.id = userData.id || undefined;
  userdataLayerValue.sp_id = userData.id ? sha256(userData.id) : undefined;
  userdataLayerValue.email = userData.email ? sha256(userData.email) : undefined;
  userdataLayerValue.flat_rate = userData.flatRate;
  userdataLayerValue.loyalty = userData.loyaltyPoints ? true : false;

  const dataLayerValue = {
    event: 'user_push',
    user: userdataLayerValue,
  };
  window.dataLayer && window.dataLayer.push(dataLayerValue);
}

function sendProductsPush(products, productPushIndex, _datalayerProducts) {
  const maxItemsPerPush = 10;
  var currentPushIndex = 1;
  const numberOfPushesNeeded = Math.ceil(products.length / maxItemsPerPush);

  while (currentPushIndex <= numberOfPushesNeeded) {
    const moduleName = `products_${productPushIndex}`;
    const initialProductIndex = (currentPushIndex - 1) * maxItemsPerPush;
    const finalProductIndex = currentPushIndex * maxItemsPerPush;
    const productsDatalayer =
      (_datalayerProducts &&
        _datalayerProducts.length &&
        _datalayerProducts.slice(initialProductIndex, finalProductIndex)) ||
      buildProductsDatalayer(products.slice(initialProductIndex, finalProductIndex));
    const dataLayerValue = {
      event: 'products_push',
      index_pushed: productPushIndex,
      [moduleName]: productsDatalayer.map((product) => { return {...product}}),
    };
    window.dataLayer.push(dataLayerValue);
    productPushIndex += 1;
    currentPushIndex += 1;
  }
  return productPushIndex;
}

function sendReloadEvent() {
  const dataLayerValue = {
    event: 'reload',
  };
  window.dataLayer.push(dataLayerValue);
}

function buildProductsDatalayer(products) {
  if (!products) return;
  return products.map((product, index) => {
    return buildProductDatalayer(product, index + 1);
  });
}

const getGtinFromSku = (sku) => {
  if (!sku) return undefined;
  if (sku.gtin) return sku.gtin;
  if (sku.variants?.length) return sku.variants[0].gtin;
  return undefined;
};

function buildProductDatalayer(product, position) {
  const currency = 'EUR';
  let sku;
  const firstColor = product._my_colors && product._my_colors[0];
  if (product._selected_skus && product._selected_skus[0]) sku = product._selected_skus[0];
  else if (firstColor) sku = firstColor;
  else sku = {};
  const fireflySku = sku.variants?.[0];

  const productDatalayer = {};
  const brand = (product.brand || {}).name;
  if (product.provider_type === 'MKP') {
    if (firstColor?.sale_price && !product.sale_price) product.sale_price = firstColor.sale_price;
    if (firstColor?.discount && !product.discount) product.discount = firstColor.discount;
    if (firstColor?.status && !product._status) {
      product._status = firstColor.status;
    }
  }

  productDatalayer.id = product.group_type !== 'SINGLE' ? product.id : buildId(sku, product.marketplace, false);
  productDatalayer.gtin = getGtinFromSku(sku);
  productDatalayer.status = product._status;
  productDatalayer.variant = sku ? sku.id || fireflySku?.id : undefined;
  productDatalayer.store_id = product.store_id;
  productDatalayer.name = product.title;
  productDatalayer.brand = brand;
  productDatalayer.position = position;
  productDatalayer.badges = product._badges;
  productDatalayer.price = buildPrice(
    product.price || sku.price || fireflySku?.price,
    product.sale_price || sku.true_price || fireflySku?.sale_price,
    product.discount || fireflySku?.discount,
    currency || 'EUR'
  );
  productDatalayer.media = {};
  productDatalayer.category = buildCategories(product.categories);
  productDatalayer.code_a = product.id;
  productDatalayer.eci_provider = product.provider_name || 'El Corte Inglés';
  productDatalayer.hierarchy = product.hierarchy;
  productDatalayer.quantity = 1;
  productDatalayer.media = buildMediaCount(product);
  productDatalayer.group_by = buildGroupBy(product);
  if (sku.hasVendor) {
    const joined = product.hierarchy.join('/');
    productDatalayer.vendor = 'eci::' + joined + '::PRODUCTOS RELACIONADOS';
  }
  if (!productDatalayer.vendor && product.vendor) {
    productDatalayer.vendor = product.vendor
  }
  return productDatalayer;
}

function buildId(sku, isMP) {
  if (!sku || !sku.id) return 'Invalid data received';
  return !isMP ? sku.id.substring(0, 15) : sku.id;
}

function buildPrice(originalPrice, finalPrice, discount, discountPercent) {
  var price = {};
  if (originalPrice !== finalPrice) {
    price.o_price = originalPrice;
  }
  price.f_price = finalPrice ? finalPrice : originalPrice;
  price.discount = !discount ? 0 : Math.round((originalPrice - finalPrice) * 100) / 100;
  price.discount_percent = !discount ? 0 : discount;
  price.currency = discountPercent;
  return price;
}

function buildGroupBy(product) {
  if (product.groupBy) return product.groupBy;
  const groupBy = product._all_variant_info
    ?.filter((variant) => variant.matches?.length > 1)
    .map((variant) => variant.title)
    .join(' > ');

  return groupBy ?? 'None';
}

function buildCategories(categories) {
  if (!categories) return [];
  return categories.map((category) => {
    return category.name;
  });
}

function buildMediaCount(product) {
  return {
    count: product.is_plp ? 1 : (product._slider_images || []).length,
  };
}

function buildWishlistProductBySku(product, sku, position) {
  const productData = { ...product };
  delete productData.ean;
  return {
    ...productData,
    status: sku.status,
    gtin: sku.gtin,
    variant: sku.id,
    position: position || sku?.position || 1,
    price: buildPrice(sku.price || product.price, sku.true_price || product.sale_price, product.discount, 'EUR'),
  };
}

function sendPromotionPushScroll(placements) {
  if (!placements) return;
  const keys = Object.keys(placements);
  if (keys.length === 0) return;

  const offSet = DEFAULT_PROMOTION_PUSH_OFFSET;
  let start = 0;
  let nPush = window.dataLayer.filter((event) => event.event === 'promotions_push').length + 1;
  let promos = [];

  keys.forEach((key) => {
    const compositions = placements[key].compositions;
    if (compositions) {
      compositions.forEach((composition) => {
        const fullArtworks = composition.artworks;
        if (fullArtworks) {
          fullArtworks.forEach((artwork) => {
            const singleArtwork = artwork?.map((art) => art?.artwork).filter((art) => art) ?? [];
            if (singleArtwork?.length) {
              singleArtwork.forEach((art) => {
                const artworkId = art.id;
                const elementId = `artwork-${artworkId}`;
                const domElement = document.getElementById(elementId);
                if (domElement) {
                  const newPromos = buildPromosArrayToPush(domElement);
                  promos = promos.concat(newPromos);
                }
              });
            }
          });
        }
      });
    }
  });

  if (promos.length === 0) return;

  //MANEJO DE IMPRESIONES
  promos.forEach(function (promo, idx) {
    if (idx % offSet === 0) {
      var payload = {
        event: 'promotions_push',
        index_pushed: nPush,
      };
      payload['promotions_' + nPush] = promos.slice(start, start + offSet);
      window.dataLayer.push(payload);
      start += offSet;
      nPush += 1;
    }
  });
}

const buildParallaxPromos = (parallaxData) => {
  const promos = [];
  parallaxData.forEach((data) => {
    var promoData = {
      id: data.id,
      name: data.name,
      pos: data.position,
      creative: data.creative,
    };
    promos.push(promoData);
  });

  promos.filter(function (promo) {
    return promo ? promo : undefined;
  });

  return uniqWith(promos, isEqual);
};

const buildTotalLookPromos = (domElementsToSearch) => {
  const promos = [];
  const totalLooks = domElementsToSearch.querySelectorAll('div.artwork.total_look[data-json]');
  totalLooks.forEach((totalLook) => {
    let lookData = undefined;
    try {
      lookData = JSON.parse(decodeURIComponent(totalLook.getAttribute('data-json')));
    } catch (error) {
      console.error('Error parsing total look artwork data', error);
    }
    if (lookData) {
      promos.push(lookData);
    }
  });
  return promos;
};

const buildPromosArrayToPush = (domElementsToSearch, megadropPromos) => {
  const simplePromos = commonAnalytics.buildSimplePromos(domElementsToSearch, megadropPromos);
  const totalLookPromos = buildTotalLookPromos(domElementsToSearch);

  return [...simplePromos, ...totalLookPromos];
};

function sendPromotionPushInitial(megadropPromos = false) {
  var start = 0;
const offSet = DEFAULT_PROMOTION_PUSH_OFFSET;
  var nPush = 1;
  const promos = buildPromosArrayToPush(document, megadropPromos);
  //MANEJO DE IMPRESIONES
  if (promos.length > 0) {
    promos.forEach(function (promo, idx) {
      if (idx % offSet === 0) {
        var payload = {
          event: 'promotions_push',
          index_pushed: nPush,
        };
        payload['promotions_' + nPush] = promos.slice(start, start + offSet);
        window.dataLayer.push(payload);
        start += offSet;
        nPush += 1;
      }
    });
  }
}

function sendProductModulesPushInitial(product_modules) {
  var start       = 0;
  var offSet      = 10;
  var nPush       = 1;
  const pageHierarchy = window.dataLayer?.[0]?.page?.hierarchy?.join?.('/') || '';
  const page_type     = window.dataLayer[0].page.type;      
  product_modules.forEach(function(p, idx) {
    p.vendor = `eci::${p.category?.join('/') || pageHierarchy}::${page_type}`;
    //MANEJO DE IMPRESIONES
    if (idx%offSet===0){
      var payload={
        'event'        : 'product_module_push_eci',
        'index_pushed' : nPush
      }
      payload['product_modules_eci_'+nPush]= product_modules.slice(start,start+offSet);
      window.dataLayer.push(payload);
      start+=offSet;
      nPush+=1;
    }
  });
}

function sendParallaxPromotionPush(spanData) {
const offSet = DEFAULT_PROMOTION_PUSH_OFFSET;
  let start = 0;
  let nPush =
    window && window.dataLayer ? window.dataLayer.filter((event) => event.event === 'promotions_push').length + 1 : 1;
  const promos = buildParallaxPromos(spanData);

  //MANEJO DE IMPRESIONES
  if (promos.length > 0) {
    promos.forEach(function (promo, idx) {
      if (idx % offSet === 0) {
        var payload = {
          event: 'promotions_push',
          index_pushed: nPush,
        };
        payload['promotions_' + nPush] = promos.slice(start, start + offSet);
        window.dataLayer.push(payload);
        start += offSet;
        nPush += 1;
      }
    });
  }
}

function sendCarouselPromotionPush(domElement, callback) {
const offSet = DEFAULT_PROMOTION_PUSH_OFFSET;
  let start     = 0;
  let nPush     =  window && window.dataLayer ? window.dataLayer.filter((event) => event.event === 'promotions_push').length + 1 : 1;
  let promos    = [];
  if (Array.isArray(domElement)) {
    domElement.forEach((element) => promos.push(...buildPromotions(element)));
  }
  else promos  = buildPromotions(domElement);
  //MANEJO DE IMPRESIONES
  if (promos.length > 0) {
    promos.forEach(function (promo, idx) {
      if (idx % offSet === 0) {
        var payload = {
          event: 'promotions_push',
          index_pushed: nPush,
        };
        payload['promotions_' + nPush] = promos.slice(start, start + offSet);
        window.dataLayer.push(payload);
        start += offSet;
        nPush += 1;
      }
    });
  }

  if(callback) return callback();
}

var buildPromotions = function( scope ){
  let promos = [];
  const colSpan= Array.from(scope.querySelectorAll('span.dataholder[data-scope=\'promotion\'][data-hasnav=\'true\']')); // Recupera las promos navegables
  if (colSpan.length){
    promos =  colSpan.map(function(span,idx){
      var oDiv          = span.closest('div.image-overlay-out') || span.closest('div.non-overlay-out') || span.closest('div.image-overlay-in') || span.closest('div.non-overlay-in')
      var processPromo  = true;
      var data          = JSON.parse(span.getAttribute('data-json'));
      var oDivDmpCdp    = span.closest('div.composition-container[data-dmp_segments], div.composition-container[data-cdp_segments]');
      var compoContainer= span.closest('div.composition-container');
      var composition   = compoContainer.closest('div.composition');
      if ( (compoContainer && window.getComputedStyle(compoContainer).display==='none') || (composition && window.getComputedStyle(composition).display==='none')) processPromo = false

      if (oDivDmpCdp && processPromo) {//Es una promo DMP o CDP
        var dmpValue      = oDivDmpCdp.getAttribute('data-dmp_segments'); //Segmentos asignados, viene cadena separada por comas
        var dmpValues     = dmpValue ? dmpValue.split(',') : [];
        var cdpValue      = oDivDmpCdp.getAttribute('data-cdp_segments');
        var cdpValues     = cdpValue ? cdpValue.split(',') : [];
        var dmpCdpValues  = dmpValues.concat(cdpValues); // Mantiene la compatibilidad si ambos segmentos están activos
        var lsKeysDmp     = ['kxelcorteinglessa_allsegs','kxelcorteinglessa_segs'];
        var dmpCdpFound   = false;

        lsKeysDmp.forEach(function(key) {
          var findValue = window.localStorage.getItem(key); //Segmentos disponibles
          if (!dmpCdpFound && findValue){
            dmpCdpValues.forEach(function(dmpCdpValueSeek){
              if (findValue.indexOf(dmpCdpValueSeek)!==-1) dmpCdpFound = true;
            });
          }
        });
        processPromo = dmpCdpFound;
      }else{
        if (oDiv && window.getComputedStyle(oDiv).display==='none') processPromo=false; //Si el contenedor div que tiene class=image-overlay-out o non-overlay-out' y está oculto, no se manda a GTM
      }

      if (processPromo){
        if (data){
          var tmp = {
            'id'       : data.id,
            'name'     : data.name, //data.name.length>50?data.name.substring(0,47)+'...' : data.name,
            'pos'      : data.position,
            'creative' : data.creative
          }
         
          if( data.creative === 'canvas' || data.creative === 'multipurpose' ) tmp = handleCanvas(span, tmp);

          return tmp;
        }else
          console.warn('loadPromotions:: No se ha especificado data-json para la promocion['+idx+']');
      }
    }).filter(function(p) {
      return p?p:undefined;
    });    
  }
  
  return uniqWith(promos, isEqual);
}

function sendPromoClickEvent({ id, name, pos, creative }) {
  const payloadCLick = {
    event: 'promotion_click',
    promotion: {
      id,
      name,
      pos,
      creative,
    },
  };
  window.dataLayer.push(payloadCLick);
}

function handleCanvas(span, tmp) {
  var canvas = span.closest('.artwork.canvas');
  if (!canvas) return tmp;
  var display = 'none';
  // Control in mobile version
  if (canvas.classList.contains('small')) {
    var canvasDisplay = window.getComputedStyle(canvas).display;
    if (canvasDisplay !== 'none') {
      var txt_div = span.closest('div:not(.links-set)');
      display = window.getComputedStyle(txt_div).display;
    }
  } else display = window.getComputedStyle(canvas).display;

  if (display !== 'none') return tmp;
}

function sendFacetDelivery(payload) {
  const { label, action } = payload;
  const pathName = window.location.pathname;
  const isSearchExperience = pathName?.includes('search-nwx');
  const eventCategoryLabel = isSearchExperience ? 'serps product filters' : 'product filters';
  const custom_event = {
    event: 'custom_event',
    event_data: {
      event_name: eventCategoryLabel,
      event_category: eventCategoryLabel,
      event_action: action,
      event_label: label,
    },
  };
  window.dataLayer.push(custom_event);
}

function sendDeliveryTypeFilterOpenEvent() {
  const event = {
    event: 'custom_event',
    event_data: {
      event_name: 'product shipping filter',
      event_category: 'product shipping filter',
      event_action: 'click on drop down',
      event_label: 'undefined', // Must be a string PD00000500-11885
    },
  };
  Array.isArray(window?.dataLayer) && window.dataLayer.push(event);
}

function sendCheckoutPush(productData) {
  const event = {
    step: 1,
    event: 'checkout_push',
    products: productData,
  };

  window.dataLayer.push(event);
}

function sendOneClickPurchaseConfirmation(purchaseDataLayer, pageData, cart, address, cookiesPolicy) {
  const products = cart.line_items.map((line) => line.data_layer);
  const user = {
    registered: true,
    flat_rate: cart.flat_rate_shipping.enabled,
    postal_code: address?.postal_code,
    id: cart.user.id,
    sp_id: cart.user?.status >= 2 ? sha256(cart.user?.id + 'baba') : undefined,
    email: cart.user?.email,
    cookies: cookiesPolicy,
  };
  const dataLayerValue = {
    system_event: true,
    event: 'purchase',
    ...purchaseDataLayer,
    products,
    user,
    page: pageData,
  };
  window.dataLayer.push(dataLayerValue);
}

function sendShowFinanciationClick() {
  window.dataLayer.push({
    event: 'custom_event',
    event_data: {
      event_name: 'finance payment',
      event_category: 'finance payment',
      event_action: 'open finance payment simulator',
    },
  });
}

function sendVideoPlayerEvent(action, videoName) {
  if (!videoName || !action || !(action in ['play', 'pause', 'ended'])) return;
  const videoEvent = {
    event: 'custom_event',
    event_data: {
      event_name: 'html5 vídeo',
      event_category: 'html5 vídeo',
      event_action: action,
      event_label: videoName,
    },
  };
  window.dataLayer.push(videoEvent);
}

function sendSearchEvent(stype) {
  const searchEvent = {
    event: 'custom_event',
    event_data: {
      event_name: 'search',
      event_category: 'browser',
      event_action: 'search',
      event_label: stype,
    },
  };
  window.dataLayer.push(searchEvent);
}

function getTotalLookProductVendor(product) {
  const categories = product.hierarchy.join('/');
  return `eci::${categories}::${VENDOR_MODULES.BUY_LOOK}`;
}

function getPageVendor(module, vendorPrefix = 'eci') {
  const pageHierarchy = window.dataLayer?.[0]?.page?.hierarchy?.join?.('/') || '';
  return `${vendorPrefix}::${pageHierarchy}::${module}`;
}

function sendCustomizationButtonClick() {
  window.dataLayer.push({
    event: 'custom_event',
    event_data: {
      event_name: 'click on product customization',
      event_category: 'product customization',
      event_action: 'click on product customization',
    },
  });
}

function sendAddCustomizedProductToCart() {
  window.dataLayer.push({
    event: 'custom_event',
    event_data: {
      event_name: 'added product customization',
      event_category: 'product customization',
      event_action: 'added product customization',
    },
  });
}

function sendCustomerNotificationEvent(variantId) {
  window.dataLayer.push({
    event: 'custom_event',
    event_data: {
      event_name: 'notify me notify me cooming soon',
      event_category: 'wishlist',
      event_action: 'notify me coming soon',
      event_label: variantId,
    },
  });
}

function sendRichRelevanceProductClick(datalayer) {
  if (datalayer) {
    const payloadCLick = {
      event: 'rr_click',
      system_event: true,
      rr_product_click: {
        ...datalayer,
      },
    };
    window.dataLayer.push(payloadCLick);
  }
}

function sendChatboxProductModule(products, index, chat_id) {
  const productData = products.map((product, i) => {
    const id = product.item_id.split('-')[2];
    const hierarchy = window.dataLayer[0].page.hierarchy?.join('/') || '';
    const categories =
      (product.list_product_categories && product.list_product_categories.map((category) => category.name)) || [];

    return {
      name: product.description,
      id: id,
      brand: product.brand.name,
      position: i + 1,
      price: {
        currency: 'EUR',
        discount: product.price_specification.price - product.price_specification.discount_price,
        discount_percent: (product.price_specification.discount_price / product.price_specification.price) * 100,
        f_price: product.price_specification.discount_price,
        o_price: product.price_specification.price,
      },
      category: categories,
      code_a: product.product_id,
      vendor: `serps_ai::${hierarchy}::chatbot`,
      parent_id: `chatID>${chat_id}`,
    };
  });

  const dataName = 'product_modules_serps_ai_' + index;

  const dataLayerValue = {
    system_event: true,
    event: 'product_module_push_serps_ai',
    index_pushed: index,
    [dataName]: JSON.parse(JSON.stringify(productData)),
  };
  window.dataLayer.push(dataLayerValue);
}

function sendProductClickChat(product, position, chat_id) {
  const id = product.item_id.split('-')[2];
  const hierarchy = window.dataLayer[0].page.hierarchy?.join('/') || '';
  const categories = product.categories?.map((cat) => cat.name) || [];

  window.dataLayer.push({
    system_event: true,
    event: 'serps_ai_click',
    serps_ai_product_click: {
      name: product.description,
      id: id,
      brand: product.brand.name,
      position: position,
      price: {
        currency: 'EUR',
        discount: product.price_specification.price - product.price_specification.discount_price,
        discount_percent: (product.price_specification.discount_price / product.price_specification.price) * 100,
        f_price: product.price_specification.discount_price,
        o_price: product.price_specification.price,
      },
      category: categories,
      code_a: product.product_id,
      vendor: `serps_ai::${hierarchy}::chatbot`,
      parent_id: `chatID>${chat_id}`,
    },
  });
}

function sendProductDetailPush(product) {
  window.dataLayer.push({
    system_event: true,
    event: 'product_pdp_push',
    product: product,
  });
}
function sendChatBoxOpen() {
  window.dataLayer.push({
    event: 'custom_event',
    event_data: {
      event_name: 'serps_ai',
      event_category: 'serps_ai',
      event_action: 'open chat',
      event_label: 'undefined',
    },
  });
}
function sendChatBoxClose() {
  window.dataLayer.push({
    event: 'custom_event',
    event_data: {
      event_name: 'serps_ai',
      event_category: 'serps_ai',
      event_action: 'close chat',
      event_label: 'undefined',
    },
  });
}
function sendChatBoxConsentAccepted() {
  window.dataLayer.push({
    event: 'custom_event',
    event_data: {
      event_name: 'serps_ai',
      event_category: 'serps_ai',
      event_action: 'consent accepted',
      event_label: 'undefined',
    },
  });
}
function loggedInByChatbot() {
  window.dataLayer.push({
    event: 'custom_event',
    event_data: {
      event_name: 'serps_ai',
      event_category: 'serps_ai',
      event_action: 'logged in by chatbot',
      event_label: 'undefined',
    },
  });
}
function sendChatBoxConsentShown() {
  window.dataLayer.push({
    event: 'custom_event',
    event_data: {
      event_name: 'serps_ai',
      event_category: 'serps_ai',
      event_action: 'consent shown',
      event_label: 'undefined',
    },
  });
}
function sendChatBoxSendMessage() {
  window.dataLayer.push({
    event: 'custom_event',
    event_data: {
      event_name: 'serps_ai',
      event_category: 'serps_ai',
      event_action: 'text search', // || voice search
      event_label: 'undefined',
    },
  });
}
function sendChatBoxClearContext() {
  window.dataLayer.push({
    event: 'custom_event',
    event_data: {
      event_name: 'serps_ai',
      event_category: 'serps_ai',
      event_action: 'clean search',
      event_label: 'undefined',
    },
  });
}
function sendChatBoxError(errorType) {
  window.dataLayer.push({
    event: 'custom_event',
    event_data: {
      event_name: 'serps_ai',
      event_category: 'serps_ai',
      event_action: 'error',
      event_label: errorType,
    },
  });
}
function sendChatBoxShowSuggestions(suggestionType) {
  window.dataLayer.push({
    event: 'custom_event',
    event_data: {
      event_name: 'serps_ai',
      event_category: 'serps_ai',
      event_action: 'suggestions shown',
      event_label: suggestionType,
    },
  });
}
function sendChatBoxSendSuggestion(filter) {
  window.dataLayer.push({
    event: 'custom_event',
    event_data: {
      event_name: 'serps_ai',
      event_category: 'serps_ai',
      event_action: 'clic on suggestion',
      event_label: filter,
    },
  });
}
function sendChatBoxShowFilters() {
  window.dataLayer.push({
    event: 'custom_event',
    event_data: {
      event_name: 'serps_ai',
      event_category: 'serps_ai',
      event_action: 'filters in chat shown',
      event_label: 'undefined',
    },
  });
}
function sendChatBoxSendFilters(action, filterTag) {
  window.dataLayer.push({
    event: 'custom_event',
    event_data: {
      event_name: 'serps_ai',
      event_category: 'serps_ai',
      event_action: action,
      event_label: filterTag,
    },
  });
}
function sendChatBoxViewMoreProducts() {
  window.dataLayer.push({
    event: 'custom_event',
    event_data: {
      event_name: 'serps_ai',
      event_category: 'serps_ai',
      event_action: 'view more products',
      event_label: 'undefined',
    },
  });
}
function sendChatBoxShowPLP() {
  window.dataLayer.push({
    event: 'custom_event',
    event_data: {
      event_name: 'serps_ai',
      event_category: 'serps_ai',
      event_action: 'products shown',
      event_label: 'undefined',
    },
  });
}
function sendChatBoxClickProduct() {
  window.dataLayer.push({
    event: 'custom_event',
    event_data: {
      event_name: 'serps_ai',
      event_category: 'serps_ai',
      event_action: 'product click',
      event_label: 'undefined',
    },
  });
}
function sendChatBoxAddToCart(source) {
  window.dataLayer.push({
    event: 'custom_event',
    event_data: {
      event_name: 'serps_ai',
      event_category: 'serps_ai',
      event_action: 'product add to cart',
      event_label: source ? source : 'undefined',
    },
  });
}
function sendChatBoxGoBackPDP() {
  window.dataLayer.push({
    event: 'custom_event',
    event_data: {
      event_name: 'serps_ai',
      event_category: 'serps_ai',
      event_action: 'click on back to chat',
      event_label: 'undefined',
    },
  });
}
function sendChatBoxLikeDislike(action) {
  window.dataLayer.push({
    event: 'custom_event',
    event_data: {
      event_name: 'serps_ai',
      event_category: 'serps_ai',
      event_action: action,
      event_label: 'undefined',
    },
  });
}
function sendChatBoxRatingShown() {
  window.dataLayer.push({
    event: 'custom_event',
    event_data: {
      event_name: 'serps_ai',
      event_category: 'serps_ai',
      event_action: 'rating shown',
      event_label: 'undefined',
    },
  });
}
function sendChatBoxRating(feedback) {
  window.dataLayer.push({
    event: 'custom_event',
    event_data: {
      event_name: 'serps_ai',
      event_category: 'serps_ai',
      event_action: 'rating',
      event_label: feedback,
    },
  });
}

function pushInsuranceAddToCart(insuranceData) {
  const InsuranceDataAddedToCart = {};
  InsuranceDataAddedToCart.product = insuranceData;
  InsuranceDataAddedToCart.event = 'add_to_cart_insurance';
  window.dataLayer.push(InsuranceDataAddedToCart);
}

function createInsuranceLineItem(insuranceData) {
  const dataLayer = window.dataLayer;
  const product = dataLayer[0].product;
  const page = dataLayer[0].page;
  return {
    associated_product: {
      brand: page?.brand,
      category: product?.category,
      id: product?.id,
      variant: product?.variant.trim()
    },
    category: product?.category,
    id: insuranceData.insurance_reference?.trim(),
    name: insuranceData.name,
    page_type: page?.type,
    price: {
      f_price: insuranceData.insurance_price,
      discount: 0,
      currency: product?.price?.currency,
    },
    quantity: insuranceData.units,
    variant: insuranceData.reference
  };
}

function sendInitialCdpProductsPush(productsData, numberOfProducts, indexPushed) {
  const dataLayer = window.dataLayer || [];
  if (dataLayer.length === 0) return;

  const productsShown = productsData.slice(0, numberOfProducts);

  addCPDProductPushEvent(productsShown, indexPushed);
}

function addCPDProductPushEvent(products, index) {
  const pageHierarchy = window.dataLayer[0].page.hierarchy.join('/');

  const payload = {
    system_event: true,
    event: 'product_module_push_art',
    index_pushed: index,
  };
  payload['product_modules_art_' + index] = products;
  products.forEach(function (p) {
    p.added = true;
    p.vendor = 'art::' + pageHierarchy + '::Te recomendamos';
  });

  window.dataLayer.push(payload);
}

function sendInsurancePrint(productVariant) {
  const dataLayer = window.dataLayer;
  const InsurancePrintData = {
    event: 'custom_event',
    event_data: {
      event_name: 'insurance up sell',
      event_category: 'insurance up sell',
      event_action: 'show insurance',
      event_label: productVariant,
    },
  };
  dataLayer.push(InsurancePrintData);
}

function sendInsuranceHireButtonClick(productVariant) {
  const dataLayer = window.dataLayer;
  const InsurancePrintData = {
    event: 'custom_event',
    event_data: {
      event_name: 'insurance up sell',
      event_category: 'insurance up sell',
      event_action: 'see insurance terms',
      event_label: productVariant,
    },
  };
  dataLayer.push(InsurancePrintData);
}

function sendInsuranceHireModalClose(productVariant) {
  const dataLayer = window.dataLayer;
  const InsurancePrintData = {
    event: 'custom_event',
    event_data: {
      event_name: 'insurance up sell',
      event_category: 'insurance up sell',
      event_action: 'close insurance terms',
      event_label: productVariant,
    },
  };
  dataLayer.push(InsurancePrintData);
}

function sendInsuranceAddToCart(productVariant) {
  const dataLayer = window.dataLayer;
  const InsurancePrintData = {
    event: 'custom_event',
    event_data: {
      event_name: 'insurance up sell',
      event_category: 'insurance up sell',
      event_action: 'add insurance to cart',
      event_label: productVariant,
    },
  };
  dataLayer.push(InsurancePrintData);
}

function sendAnalyticsExpertContactInit() {
  const dataLayer = window.dataLayer;
  const payLoad = {
    'event': 'custom_event',
    'event_data':{
      'event_category': 'store sales assistant',
      'event_action': 'click contact assistant',
      'event_label': undefined
    }
  };

  dataLayer.push(payLoad);
}

function sendAnalyticsExpertCustom(action, label) {
  const dataLayer = window.dataLayer;
  const payLoad = {
    'event': 'custom_event',
    'event_data': {
      'event_category': 'store sales assistant',
      'event_action': action,
      'event_label': label
    }
  }
  dataLayer.push(payLoad);
}

function sendAnalyticsExpertVendorId(reference, vendorId, centerId) {
  const dataLayer = window.dataLayer;
  const hasVendorInfo = dataLayer && dataLayer.id_vendor && dataLayer.id_vendor.length > 0;
  if (!reference || !vendorId || !centerId || hasVendorInfo) return;

  const callVendor = {
    'event': 'call_vendor',
    'vendor_info': {
      'p_variant': reference,
      'id_vendor': vendorId,
      'id_center': centerId,
      'fx_call': new Date().getTime()
    }
  };
  dataLayer.push(callVendor);
}

function sendAnalyticsExpertContactType(type) {
  const dataLayer = window.dataLayer;
  var payLoad = {
    'event': 'custom_event',
    'event_data': {
      'event_category': 'store sales assistant',
      'event_action': 'click connect with assistant',
      'event_label': type
    }
  };

  dataLayer.push(payLoad);
}

function sendAnalyticsExpertPdp(recommendedProductDataLayer, vendorId) {
  const dataLayer = window.dataLayer;
  if (Array.isArray(recommendedProductDataLayer)) recommendedProductDataLayer = recommendedProductDataLayer[0];
  if (!recommendedProductDataLayer || !recommendedProductDataLayer.product) return;

  const datalayerProduct = recommendedProductDataLayer.product;
  datalayerProduct.id_vendor = vendorId;

  var payLoad = {
    'event': 'product_pdp_push',
    'index_pushed': 1,
    'product_1': datalayerProduct
  };

  dataLayer.push(payLoad);
}

function sendAddProductAndInsuranceToCartAnalytics(productLineItems, insuranceLineItems, indexPushed) {
  const productsData = [];
  const insuranceLineItem = createInsuranceLineItem(insuranceLineItems);
  productsData.push({ ...productLineItems }, { ...insuranceLineItem });
  sendMultipleAddToCartClick(productsData, indexPushed);
}

function sendContextMenuItemAnalytics(item, action){
  const url = item.url.split('?')?.[0]?.replace(/^\//,'').replace(/\/$/,'');
  const topHierarchy = window?.dataLayer?.[0]?.page?.hierarchy?.[0] ?? 'Home';
  const event = {
    event: 'custom_event',
    event_data: {
      event_name: `mundo ${topHierarchy}`,
      event_category: `mundo ${topHierarchy}`,
      event_action: action,
      event_label: `${url}>${item.name}`,
    },
  };

  Array.isArray(window?.dataLayer) && window.dataLayer.push(event);
}

function resendUserEvent() {
  for(let i = 0 ; i < window.dataLayer.length; i++) {
    if (window.dataLayer[i].event === 'user_push') {
      window.dataLayer.push(window.dataLayer[i]);
      break;
    }
  }
}

function sendCustomEvent(eventData) {
  window.dataLayer.push({
    event: 'custom_event',
    event_data: eventData,
  });
}

function sendShowSayfitEvent(eventAction){
  const eventData = {
    event_name: 'size_guide',
    event_category: 'sayfit',
    event_action: eventAction,        
  }

  sendCustomEvent(eventData);
}

function setThirdDriver(productData) {
  const validThirdDrivers = ['tour_terrazayjardin'];
  const searchParams = new URLSearchParams(window.location.search);
  const thirdDriver = searchParams.get('third');
  if (thirdDriver && validThirdDrivers.includes(thirdDriver)) {
    productData.third_driver = thirdDriver;
  } 
}

function sendShowCharacteristicsModalEvent(modalName = '') {
  const eventData = {
    event_name: 'view description',
    event_category: 'view product description',
    event_action: `open ${modalName}`,
    event_label: undefined,
  }

  sendCustomEvent(eventData);
}

module.exports = {
  PRODUCT_PUSH_TYPES,
  VENDOR_MODULES,
  buildProductDatalayer: buildProductDatalayer,
  buildWishlistProductBySku,
  buildGroupBy,
  createInsuranceLineItem,
  sendAddCustomizedProductToCart: sendAddCustomizedProductToCart,
  sendAddProductAndInsuranceToCartAnalytics: sendAddProductAndInsuranceToCartAnalytics,
  addProductToCartHandler: addProductToCartHandler,
  sendAddUnitsInCart: sendAddUnitsInCart,
  sendAddToCartIntent: sendAddToCartIntent,
  sendAddToWishListClick: sendAddToWishListClick,
  sendCartProducts: sendCartProducts,
  sendChangeUnitsInCart: sendChangeUnitsInCart,
  sendCustomizationButtonClick: sendCustomizationButtonClick,
  sendCustomerNotificationEvent,
  sendMultipleAddToCartClick: sendMultipleAddToCartClick,
  sendProductClick: sendProductClick,
  sendProductPreviewLinkToPDPClick,
  sendProductModulesPrint: sendProductModulesPrint,
  sendProductsPush: sendProductsPush,
  sendPromotionPushInitial: sendPromotionPushInitial,
  sendProductModulesPushInitial:sendProductModulesPushInitial,
  sendParallaxPromotionPush: sendParallaxPromotionPush,
  sendPromotionPushScroll: sendPromotionPushScroll,
  sendRemoveFromCartClick: sendRemoveFromCartClick,
  sendReloadEvent: sendReloadEvent,
  sendUserPush: sendUserPush,
  sendFacetDelivery: sendFacetDelivery,
  sendOneClickPurchaseConfirmation: sendOneClickPurchaseConfirmation,
  sendCheckoutPush: sendCheckoutPush,
  sendShowFinanciationClick: sendShowFinanciationClick,
  sendPromoClickEvent,
  sendVideoPlayerEvent,
  sendSearchEvent,
  getTotalLookProductVendor,
  getPageVendor,
  sendAddToCart,
  sendDeliveryTypeFilterOpenEvent,
  sendRichRelevanceProductClick,
  sendChatboxProductModule,
  sendProductClickChat,
  sendProductDetailPush,
  sendChatBoxOpen,
  sendChatBoxClose,
  sendChatBoxConsentAccepted,
  loggedInByChatbot,
  sendChatBoxConsentShown,
  sendChatBoxSendMessage,
  sendChatBoxClearContext,
  sendChatBoxError,
  sendChatBoxShowSuggestions,
  sendChatBoxSendSuggestion,
  sendChatBoxShowFilters,
  sendChatBoxSendFilters,
  sendChatBoxShowPLP,
  sendChatBoxClickProduct,
  sendChatBoxAddToCart,
  sendChatBoxGoBackPDP,
  sendChatBoxLikeDislike,
  sendChatBoxRatingShown,
  sendChatBoxRating,
  sendChatBoxViewMoreProducts,
  sendInitialCdpProductsPush,
  addCPDProductPushEvent,
  pushInsuranceAddToCart,
  sendCarouselPromotionPush,
  sendInsurancePrint,
  sendInsuranceHireButtonClick,
  sendInsuranceHireModalClose,
  sendInsuranceAddToCart,
  sendAnalyticsExpertContactInit,
  sendAnalyticsExpertVendorId,
  sendAnalyticsExpertCustom,
  sendAnalyticsExpertContactType,
  sendAnalyticsExpertPdp,
  sendContextMenuItemAnalytics,
  resendUserEvent,
  sendCustomEvent,
  sendShowSayfitEvent,
  sendShowCharacteristicsModalEvent,
};
