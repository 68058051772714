<template>
  <component
    :is="artworksMap[props.type]"
    :artwork="props.artwork"
    @open-in-modal="OpenInModal($event)"></component>
</template>
<script>
export default { name: 'Artwork' };
</script>

<script setup>
  const artworksMap = {
    multipurpose_vue: defineAsyncComponent(() => import('CommonComponents/Sheets/Artworks/Multipurpose.vue')),
    separator_vue: defineAsyncComponent(() => import('CommonComponents/Sheets/Artworks/Separator.vue')),
  }
  
  const emits = defineEmits(['openInModal']);

  const props = defineProps({
    artwork: {
      type: Object,
      required: true,
    },
    type: {
      type: [String, null],
      default: null,
      required: true,
    },
  });

  function OpenInModal(event) {
    emits('openInModal', event);
  }
</script>